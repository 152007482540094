<template>
  <div>
    <div class="striped-title">{{ L("热门岗位") }}</div>
    <div class="">
      <div
        class=""
        v-for="(it, index) in list"
        :key="index"
      >
        <div class="title" style="cursor:pointer" @click="openJob(it)">
          {{ L(it.name) }}
        </div>

        <div class="footer">
          <div class="type">{{ L(it.major) }}</div>
          <div class="user">{{ L(it.plan_num) }}{{ L("人") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    openJob(item) {
      this.$router.push({
       path: "/job-detail?uuid=" + item.uuid,
       replace: true
       });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 8px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.type {
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}
.user {
  font-size: 12px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}
</style>