<template>
  <div>
    <div class="company-info">
      <div class="container">
        <div v-if="loading">
          <a-spin />
        </div>
        <template v-else>
          <img
            v-if="detail.logo_img"
            class="logo"
            :src="detail.logo_img"
            alt=""
          />
          <div v-else class="logo2 bg-info">
            <span class="white f16" v-text="L(detail.short_name)"></span>
          </div>
          <div class="content">
            <div class="company-name">{{ L(detail.name) }}</div>
            <div class="company-description">{{ L(detail.size) }}</div>
            <div class="data">
              <div class="item">
                <div class="value">
                  <span class="num">{{ L(detail.type) }}</span>
                </div>
                <div class="label">{{ L("企业类型") }}</div>
              </div>
              <div class="item">
                <div class="value">
                  <span class="num">{{ L(detail.area) }}</span>
                  <!-- <span class="unit">{{ L("人") }}</span> -->
                </div>
                <div class="label">{{ L("所在区域") }}</div>
              </div>
              <div class="item">
                <div class="value">
                  <span class="num">{{ detail.job_num }}</span>
                  <span class="unit">{{ L("个") }}</span>
                </div>
                <div class="label">{{ L("招聘职位") }}</div>
              </div>
            </div>
          </div>

          <button
            class="follow"
            :class="isCollected ? 'active' : ''"
            @click="addCollection"
          >
            {{ isCollected ? L("取消收藏") : L("收藏") }}
          </button>
        </template>
      </div>
    </div>

    <div class="company-wrapper" v-if="!loading">
      <div class="container">
        <div class="company-detail">
          <div class="company">
            <div class="company-profile">
              <div class="company-title">{{ L("经营范围") }}</div>
              <div class="company-name"></div>
              <p v-text="L(detail.business)"></p>
            </div>

            <div v-if="detail.lng" class="company-title mt20">{{ L("公司位置") }}</div>

            <div v-if="detail.lng" class="map-panel">
              <div class="company-address">
                <img src="@/assets/icon-location2.png" alt="" />
                <span style="padding-right: 20px"
                  >{{ L(detail.province) }} {{ L(detail.city) }}
                  {{ L(detail.area) }} {{ L(detail.address) }}</span
                >
              </div>
              <div id="company-map"></div>
            </div>

            <div v-if="jobList.length>0" id="recruitment-position" class="company-title">
              {{ L("在招职位") }}
            </div>

            <div v-if="jobList.length>0" class="job-list mt20">
              <div class="job-item" v-for="(it, index) in jobList" :key="index">
                <div style="width: 100%">
                  <div class="job-title primary">{{ L(it.name) }}</div>
                  <div class="job-salary">{{ it.plan_num }}人</div>
                  <button @click="add(it)">{{ L("立即申请") }}</button>
                </div>
                <div>
                  <div class="job-education">
                    {{ L("招聘专业") }}：{{ L(it.major||'-') }}
                  </div>
                </div>
                <div>
                  <div class="job-education">
                    {{ L("招聘类型") }}：{{ it.type }}
                  </div>
                </div>
                <div>
                  <div class="job-education">
                    {{ L("专业要求") }}：{{ L(it.major_detail) }}
                  </div>
                </div>
                <div>
                  <div class="job-education">
                    {{ L("工作内容") }}：{{ L(it.job_detail) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="company-extra-info">
            <AdList />
            <RecommendedPosition :list="otherList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import AdList from "@/components/ad-list";
import RecommendedPosition from "@/components/recommended-position";
import { fetchDetail } from "@/api/company";
import { addorCancelCollection, isCollect } from "@/api/me";

export default {
  components: {
    AdList,
    RecommendedPosition,
  },

  data() {
    return {
      loading: false,
      detail: {},
      jobList: [],
      otherList: [],
      adList: [],
      activeAnchor: "#company-homepage",

      isCollected: false, // 是否收藏
    };
  },

  mounted() {
    const { query } = this.$route;
    const { uuid } = query || {};
    if (uuid) {
      this.getDetail(uuid);
    }
  },

  // beforeDestroy() {
  //   if (this.map) {
  //     this.map.destroy();
  //   }
  // },

  methods: {
    getDetail(uuid) {
      this.loading = true;
      fetchDetail({
        uuid,
      })
        .then((res) => {
          if (Array.isArray(res.jobs)) {
            this.jobList = res.jobs;
          }
          if (Array.isArray(res.others)) {
            this.otherList = res.others;
          }
          if (res.company) {
            this.detail = res.company;
            this.getCollection();
          }
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setMap();
          }, 1000);
        });
    },

    setMap() {
      if (!this.detail.lng) {
        return;
      }
      const T = window.T;
     const map = new T.Map('company-map');
     const lnglat = new T.LngLat(parseFloat( this.detail.lng), parseFloat( this.detail.lat));
      map.centerAndZoom(lnglat, 12);
      map.enableScrollWheelZoom();
      map.enableDoubleClickZoom();

      const  control = new T.Control.Zoom();
      map.addControl(control);

      var marker = new T.Marker(lnglat);
      map.addOverLay(marker);
    },

    getCollection() {
      isCollect({
        uuid: this.detail.uuid,
      }).then((res) => {
        if (res.info) {
          this.isCollected = true;
        } else {
          this.isCollected = false;
        }
      });
    },

    addCollection() {
      addorCancelCollection({
        uuid: this.detail.uuid,
        name: this.detail.name,
        type: "COMPANY",
      }).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.getCollection();
        }
      });
    },

    // 立即申请
    add(item) {
      this.$router.push("/job-detail?uuid=" + item.uuid);
    },
  },
};
</script>



<style lang="less" scoped>
.company-info {
  background: #004BB0;
  padding-bottom: 34px;
}
.company-info .container {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
}
.company-info .logo {
  width: 125px;
  height: 125px;
  margin-right: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.company-info .logo2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  box-sizing: border-box;
  background-color: #004BB0;
  width: 125px;
  height: 125px;
  padding: 2px;
  margin-right: 10px;
  box-sizing: border-box;
  color:#fff;
}

.company-info .content {
  flex: 1;
}
.company-info .company-name {
  font-size: 22px;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 10px;
}
.company-info .company-description {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 16px;
}
.company-info .data {
  display: flex;
  align-items: center;
}
.company-info .data .value {
  color: #004BB0;
  font-size: 12px;
}
.company-info .data .num {
  font-size: 26px;
}
.company-info .data .label {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  opacity: 0.3;
}
.company-info .data .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-right: 24px;
  margin-right: 24px;
}
.company-info .data .item::after {
  content: "";
  position: absolute;
  top: 20%;
  right: 0;
  bottom: 20%;
  width: 1px;
  background: #000000;
  opacity: 0.1;
}
.company-info .data .item:last-child::after {
  width: 0;
}

.company-info .follow {
  margin-left: 24px;
  width: 136px;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 400;
  color: #004BB0;
  border: 2px solid #004BB0;
  box-shadow: 0px 0px 10px rgba(58, 218, 162, 0.2);
  background-color: #fff;
  margin-bottom: 40px;
  cursor: pointer;
}

.company-info .active {
  background-color: #004BB0;
  color: #fff;
}

.company-info .follow:hover {
  color: #fff;
  background-color: #004BB0;
}

.company-info .icon {
  height: 18px;
  cursor: pointer;
}

.company-wrapper {
  background-color: #f3f3f3;
  padding-top: 25px;
  padding-bottom: 36px;
}

.company-detail {
  background-color: #fff;
  display: flex;
  padding: 24px;
}

.company {
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 24px;
  padding-right: 24px;
}

.company .company-title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
.company-profile .company-name {
  margin-top: 10px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
.company-profile > p {
  font-weight: 400;
  line-height: 28px;
  color: #000000;
  opacity: 0.5;
  padding: 10px 0;
}
.company-profile .spread-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: -10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.3;
  cursor: pointer;
  margin-bottom: 55px;
}
.company-profile .spread-button .iconfont {
  transition: all 0.3s;
}
.company-profile .spread-button .rotate {
  transform: rotateZ(-180deg);
}

.map-panel {
  margin-top: 24px;
  margin-bottom: 75px;
  height: 380px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.company-address {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.company-address img {
  height: 17px;
  margin-right: 7px;
}

#company-map {
  height: 420px;
}

.company-extra-info {
  width: 270px;
}

.striped-title {
  font-size: 14px;
  color: #000;
  opacity: 0.8;
  font-weight: bold;
  padding-bottom: 14px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.base-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 32px;
}
.base-info img {
  width: 18px;
  margin-right: 20px;
}
.manage-title {
  font-size: 14px;
  color: #000;
  opacity: 0.8;
  font-weight: bold;
  padding-bottom: 14px;
}
.manage-title-decoration {
  display: flex;
  margin-bottom: 24px;
}
.manage-title-decoration > div {
  width: 15px;
  height: 2px;
  background-color: #00c1c8;
  margin-right: 6px;
}
.manage-title-decoration > div:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}
.manage-avatar {
  width: 84px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}
.manage-name {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  opacity: 0.8;
  margin-top: 18px;
  margin-bottom: 18px;
}
.manage-description {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 36px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags .tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 25px;
  margin-bottom: 8px;
  margin-right: 8px;
  background: rgba(32, 49, 127, 0.05);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.job-item {
  display: flex;
  flex-wrap: wrap;
}
.job-item > div {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.job-item > div:first-child {
  margin-top: 8px;
  margin-bottom: 14px;
}
.job-item .job-title {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.job-item .job-salary {
  margin-left: 18px;
  font-size: 18px;
  font-weight: 300;
  color: #fabb1c;
}
.job-item button {
  margin-left: auto;
  width: 109px;
  height: 32px;
  line-height: 32px;
  background: #004BB0;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.job-education {
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}
.job-time {
  margin-left: auto;
  font-size: 12px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}
</style>


<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .company-info {
    background: #004BB0;
    padding: 12px;
  }
  .company-info .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
  }
  .company-info .logo {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
  }

  .company-info .data {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .company-info .data .item::after {
    content: "";
    width: 0;
  }

  .company-info .data .num {
    font-size: 20px;
    font-weight: bold;
  }

  .company-info .data .item {
    padding-right: 0;
    margin-right: 0;
  }

  .company-info .follow {
    display: block;
    margin: 12px auto 0;
  }

  .company-wrapper {
    background-color: #f3f3f3;
    padding: 12px 0;
  }

  .company-detail {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .company {
    flex: 1;
    border-right-width: 0;
    margin-right: 0;
    padding-right: 0;
  }

  .job-item > div {
    width: 100%;
  }

  .company-extra-info {
    width: 100%;
  }
}
</style>