<template>
  <div>
    <div class="item" v-for="(item, index) in adList" :key="index">
      <a target="_blank" :href="item.url">
        <img style="width: 100%" :src="item.face_file" /></a>
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/ad";
export default {
  data() {
    return {
      adList: [],
    };
  },
  mounted() {
    fetchList({
      code: "DETAIL_BANNER",
    }).then((res) => {
      if (Array.isArray(res)) {
        this.adList = res;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.item {
  padding-bottom: 24px;
}
</style>